<template>
  <div
    class="banner-wrapper"
    style="background-color: var(--v-header-bg)"
  >
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xs="12"
        >
          <spreadsheet />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'SpreadsheetView',
  components: {
    Spreadsheet: () => import('@/components/spreadsheet/index'),
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.side-text {
  margin-top: 10px;
  padding: 20px;
}
.banner-wrapper {
  padding-top: 50px;
}
@media (min-width: 960px) {
  .side-text {
    margin-top: 200px;
    padding: 20px;
  }
  .banner-wrapper {
    padding-top: 100px;
  }
}
</style>
